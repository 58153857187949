<template>
    <table class="table-widget">
        <thead v-if="meta.dataType == 'Campaigns'">
            <tr>
                <th class="column-title campaign_name">
                    Campaign Name
                </th>
                <template v-if="stackMetrics">
                    <th
                        v-for="(header, h) in headers"
                        :key="h"
                        class="column-title"
                        :class="header.key">
                        <template v-if="header.bottom">
                            <span class="top">{{ header.top.name }}</span>
                            <span class="bottom">{{ header.bottom.name }}</span>
                        </template>
                        <template v-else>
                            {{ header.top.name }}
                        </template>
                    </th>
                </template>
                <template v-else>
                    <th
                        v-for="(header, h) in headers"
                        :key="h"
                        class="column-title"
                        :class="header.key">
                        {{ header.name }}
                    </th>
                </template>
            </tr>
        </thead>
        <thead v-else-if="meta.dataType == 'Inventory'">
            <tr>
                <th class="column-title vin">
                    VIN
                </th>
                <th class="column-title make">
                    Make
                </th>
                <th class="column-title model">
                    Model
                </th>
                <th class="column-title condition">
                    Condition
                </th>
                <template v-if="stackMetrics">
                    <th
                        v-for="(header, h) in headers"
                        :key="h"
                        class="column-title"
                        :class="header.key">
                        <template v-if="header.bottom">
                            <span class="top">{{ header.top.name }}</span>
                            <span class="bottom">{{ header.bottom.name }}</span>
                        </template>
                        <template v-else>
                            {{ header.top.name }}
                        </template>
                    </th>
                </template>
                <template v-else>
                    <th
                        v-for="(header, h) in headers"
                        :key="h"
                        class="column-title"
                        :class="header.key">
                        {{ header.name }}
                    </th>
                </template>
                <th class="column-title days_listed">
                    Days Listed
                </th>
                <th class="column-title status">
                    Status
                </th>
            </tr>
        </thead>
        <tbody v-if="meta.dataType == 'Campaigns'">
            <tr
                v-for="(campaign, c) in filteredMetrics"
                :key="c"
                class="campaign">
                <td class="column-value campaign_name">
                    {{ campaign.campaign_name.formatted }}
                </td>
                <template v-if="stackMetrics">
                    <td
                        v-for="(metric, m) in campaign.metrics"
                        :key="m"
                        class="column-value">
                        <template v-if="metric.bottom">
                            <span class="top">{{ metric.top.formatted }}</span>
                            <span class="bottom">{{ metric.bottom.formatted }}</span>
                        </template>
                        <template v-else>
                            {{ metric.top.formatted }}
                        </template>
                    </td>
                </template>
                <template v-else>
                    <td
                        v-for="(metric, m) in campaign.metrics"
                        :key="m"
                        class="column-value"
                        :class="metric.key">
                        {{ metric.formatted }}
                    </td>
                </template>
            </tr>
        </tbody>
        <tbody v-else-if="meta.dataType == 'Inventory'">
            <tr
                v-for="(vehicle, v) in filteredMetrics"
                :key="v"
                class="vehicle">
                <td class="column-value vin">
                    {{ vehicle.vin.formatted }}
                </td>
                <td class="column-value make">
                    {{ vehicle.make.formatted }}
                </td>
                <td class="column-value model">
                    {{ vehicle.model.formatted }}
                </td>
                <td class="column-value condition">
                    {{ vehicle.condition.formatted }}
                </td>
                <template v-if="stackMetrics">
                    <td
                        v-for="(metric, m) in vehicle.metrics"
                        :key="m"
                        class="column-value">
                        <template v-if="metric.bottom">
                            <span class="top">{{ metric.top.formatted }}</span>
                            <span class="bottom">{{ metric.bottom.formatted }}</span>
                        </template>
                        <template v-else>
                            {{ metric.top.formatted }}
                        </template>
                    </td>
                </template>
                <template v-else>
                    <td
                        v-for="(metric, m) in vehicle.metrics"
                        :key="m"
                        class="column-value"
                        :class="metric.key">
                        {{ metric.formatted }}
                    </td>
                </template>
                <td class="column-value days_listed">
                    {{ vehicle.days_listed.formatted }}
                </td>
                <td class="column-value status">
                    {{ vehicle.status.formatted }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'Table',
    props: {
        meta: {
            type: Object,
            required: true
        },
        metrics: {
            type: Array,
            required: true
        },
        size: {
            type: String,
            default: 'full'
        }
    },
    computed: {
        headers() {
            let firstRowMetrics = this.metrics?.[0]?.metrics ?? 0;
            if(!firstRowMetrics) {
                return [];
            }

            if(this.stackMetrics) {
                let stackedHeaders = [];
                for (let h = 0; h < firstRowMetrics.length; h+=2) {
                    stackedHeaders.push({
                        top: { name: firstRowMetrics[h].name },
                        bottom: firstRowMetrics[h+1] ? { name: firstRowMetrics[h+1].name } : false
                    });
                }
                return stackedHeaders;
            } else {
                return firstRowMetrics?.map(({ name })=>({ name }));
            }
        },
        currentPage() {
            return Math.round((this.meta.pagination?.startingRow || 1) / 10 + 1);
        },
        stackMetrics() {
            return this.meta?.stackMetrics || false;
        },
        filteredMetrics() {
            let rows = this.metrics;
            if(this.meta.sortBy && this.meta.sortBy.metric && this.meta.sortBy.direction) {
                const sortBy = this.meta.sortBy.metric;
                switch(this.meta.sortBy.direction) {
                    case 'asc':
                        rows.sort((a,b) => {
                            let aMetric = a.metrics.find(m => m.key == sortBy);
                            let bMetric = b.metrics.find(m => m.key == sortBy);
                            return aMetric.value < bMetric.value ? -1 : 1;
                        });
                        break;
                    case 'desc':
                        rows.sort((a,b) => {
                            let aMetric = a.metrics.find(m => m.key == sortBy);
                            let bMetric = b.metrics.find(m => m.key == sortBy);
                            return aMetric.value > bMetric.value ? -1 : 1;
                        });
                        break;
                }
            }
            if(this.stackMetrics) {
                let newRows = [];
                rows.forEach(row => {
                    let newRow = JSON.parse(JSON.stringify(row));
                    delete newRow.metrics;
                    newRow.metrics = [];
                    for(let m = 0; m < row.metrics.length; m+=2) {
                        newRow.metrics.push({
                            top: row.metrics[m],
                            bottom: row.metrics[m+1] || false
                        });
                    }
                    newRows.push(newRow);
                });
                rows = newRows;
            }
            const startingRow = this.meta.pagination?.startingRow - 1 || 0;
            const numRows = this.meta.size == 'half' ? 5 : 10;
            return rows.slice(startingRow, startingRow + numRows);
        },
    },
    created() {
        // Pagination Logic
        // Only for Full Layout
        if(this.size == 'full' && (this.meta.pagination?.startingRow + 10 || 11) < this.metrics.length) {
            const lastPage = this.meta.pagination?.lastPage || 'All';
            if(lastPage !== 'All' && lastPage === this.currentPage) {
                return;
            }
            this.$emit('add-page', {
                startingRow: this.meta.pagination?.startingRow + 10 || 11,
                lastPage
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.table-widget {
    border-collapse: collapse;
    margin: 0 auto;
    width: 100%;
}
.column-title {
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    padding: 5px;
    text-align: center;
    .top,
    .bottom {
        display: block;
        float: right;
        clear: right;
        text-align: right;
    }
    .top {
        border-bottom: 1px solid $gray-light;
    }
}
.campaign,
.vehicle {
    height: 55px;
    &:nth-of-type(2n-1) {
        background: white;
    }
}
.column-value {
    font-size: 12px;
    padding: 5px;
    text-align: center;
    .top,
    .bottom {
        display: block;
        float: right;
        clear: right;
        text-align: right;
    }
    .top {
        border-bottom: 1px solid $gray-light;
    }

}
</style>